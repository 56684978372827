











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class GraphChanger extends Vue {
    isGraphView: boolean = true;

    @Prop({
        required: false,
        type: Boolean,
    })
    private value!: boolean;

    handleChange(value: boolean) {
        this.isGraphView = !this.isGraphView;
        this.$emit('input', this.isGraphView);
    }
}
