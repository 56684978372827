


















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DemandGraph from '@/modules/home/march-demo/demand-graph.vue';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';

@Component({
    components: { DemandGraph, Tooltip },
})
export default class DemandGraphComponent extends Vue {
    @Inject(HotelHomepageServiceS) protected hotelHomepageService!: HotelHomepageService;

    get demandData() {
        return this.hotelHomepageService.demandsData;
    }

    get demandLabels() {
        return this.hotelHomepageService.demandsLabels;
    }
}
