



































import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Year from '@/modules/common/types/year.type';
import Month from '@/modules/common/types/month.type';
import MonthChanger from '@/modules/common/components/month-changer.vue';
import EventsDayList from '@/modules/events/components/events-day-list.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: {
        MonthChanger, EventsDayList,
    },
})
export default class EventsMonthListContainer extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get monthEvents() {
        return this.eventsManagerService.getEventsByMonth({ month: this.month, year: this.year });
    }

    isLastItem(index: string) :boolean {
        return Object.keys(this.monthEvents).length - 1 === Number(index);
    }
}
