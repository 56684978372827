














import { Vue, Component, Prop } from 'vue-property-decorator';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import RatesDemands from '@/modules/rates/components/rates-demands.vue';
import GraphDateLegend from '@/modules/home/components/graph-date-legend.vue';

@Component({
    components: { RatesDemands, Demand, GraphDateLegend },
})
export default class DemandGraph extends Vue {
    @Prop({
        required: false,
        type: Array,
    })
    private data!: number[];

    @Prop({
        required: false,
        type: Array,
    })
    private labels!: number[];
}
