













































import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import available from '@/modules/rates/components/calendar/cards/available.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PriceFilter from '@/modules/common/filters/price.filter';
import Day from '@/modules/common/types/day.type';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import Currency from '@/modules/common/components/currency.vue';
import HomeService, { HomeServiceS } from '@/modules/home/home.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import CompsetModel from '@/modules/compsets/models/compset.model';

@Component({
    components: { Flag, Currency, CalendarEventsContainer },
    filters: { PriceFilter },
})
export default class RatesGraphTooltipContainer extends Vue {
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(HotelsServiceS) protected hotelsService!: HotelsService;
    @Inject(HomeServiceS) protected homeService!: HomeService;
    @Inject(HomeFiltersServiceS) protected homeFiltersService!: HomeFiltersService;
    @Inject(CompsetsServiceS) protected compsetsService!: CompsetsService;

    @Prop({
        required: true,
        type: String,
    })
    private date!: string;

    getDateFormat(date: string) {
        return new Date(date)
            ? `${this.$t(`month.${new Date(date).getMonth()}`)} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`
            : '';
    }

    get currency() {
        return this.homeService.getRatesCurrency(this.homeFiltersService.ratesPos);
    }

    get day() : Day {
        return (new Date(this.date) ? (new Date(this.date)).getDate() : null) as Day;
    }

    get myPrice(): number | null {
        return this.homeService.getMyPrice(this.day);
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get compsetsPrices() {
        if (this.compsetsService.compsets) {
            return this.compsetsService.compsets.filter(compset => this.homeService.isRatesDocumentAvailable(compset))
                .map((compset: CompsetModel) => ({
                    price: this.homeService.getCompsetPrice(this.day, compset.id),
                    compsetName: compset.name,
                    compsetType: compset.type,
                }));
        }
        return [];
    }
}
