import { render, staticRenderFns } from "./rates-graph-compset-label.vue?vue&type=template&id=9c2836d6&scoped=true&"
import script from "./rates-graph-compset-label.vue?vue&type=script&lang=ts&"
export * from "./rates-graph-compset-label.vue?vue&type=script&lang=ts&"
import style0 from "./rates-graph-compset-label.vue?vue&type=style&index=0&id=9c2836d6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c2836d6",
  null
  
)

export default component.exports