















































import { Vue, Component } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import CiScore from '@/modules/score/components/ci-score.vue';
import ScoreContainer from '@/modules/home/components/score/score-container.vue';
import RatesContainer from '@/modules/home/components/rates/rates-container.vue';
import DemandGraphComponent from '@/modules/home/components/demand/demand-graph-component.vue';
import VisibilityContainer from '@/modules/home/components/home-visibility/visibility-container.vue';
import HomeHotelTitle from '@/modules/home/components/home-hotel-title.vue';
import GuestReviewsContainer from '@/modules/home/components/ranking/guest-reviews-container.vue';
import EventsContainer from '@/modules/home/components/events/events-container.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';

@Component({
    components: {
        PageWrapper,
        VisibilityContainer,
        DemandGraphComponent,
        RatesContainer,
        ScoreContainer,
        CiScore,
        DateDocumentFilter,
        EventsContainer,
        HomeHotelTitle,
        GuestReviewsContainer,
    },
})
export default class HomePage extends Vue {}
