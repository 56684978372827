











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomDesignSelect from '@/modules/common/components/ui-kit/custom-design-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';

@Component({
    components: { CustomDesignSelect },
})
export default class PosRatesFilter extends Vue {
    @Inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    get pos() {
        return this.homeFiltersService.ratesPos;
    }
    set pos(value) {
        this.homeFiltersService.ratesPos = value;
    }
    get posItems(): Item[] {
        return this.homeFiltersService.posItems.map(pos => ({
            value: pos,
            name: pos,
        }));
    }

    get isDisabled() {
        return this.homeFiltersService.isCugOfMobileApp;
    }
}
