





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

@Component
export default class VisibilityGraphLabels extends Vue {
    @Prop({ type: Boolean }) skeleton!: boolean;
}
